import React, { useRef } from 'react';
import {
  useLocationQuery,
  useSearchLocationContext
} from '../../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useRouter } from 'next/router';
import { SearchForm } from '../../../SearchForm/SearchForm';
import { getTranslateRoutes } from '../../../../lib/i18n/getTranslateRoutes';
import { IResultsQueryParams } from '../../../../pages/rent-property/[cityTitle]';

export const ExploreViewSearch = () => {
  const router = useRouter();
  const inputRef = useRef<any>();
  const { searchTerm, setSearchTerm, district, selectedLocations } =
    useSearchLocationContext();
  const query = useLocationQuery(district?.title || 'barcelona', {
    enabled: district !== undefined,
    onSuccess: () => {
      inputRef.current?.focus();
    }
  });

  const onSearchButtonClick = () => {
    if (district && selectedLocations.length) {
      const queryParams: IResultsQueryParams = {};
      const selectedLocations_ = selectedLocations.filter(
        location => !!location
      );

      if (selectedLocations_.length) {
        queryParams.place_in = selectedLocations_;
      }

      router.push({
        pathname: `${getTranslateRoutes(router.locale, 'listing')}/${
          district?.title.toLowerCase() || 'barcelona'
        }`,
        query: queryParams as any
      });
    }
  };

  return (
    <SearchForm
      inputRef={inputRef}
      onButtonClick={onSearchButtonClick}
      value={searchTerm}
      onChange={setSearchTerm}
      isExplore={true}
      results={query?.data}
    />
  );
};
