import React from 'react';
import {
  IListing,
  Listing
} from '../../../../../../shared/models/listing/listing.model';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { getFormattedPrice } from '../../../../../../shared/services/listing/listing.service';

import styles from './ListingCard.module.css';

interface IListingCardImageProps {
  listing: IListing;
}

export const ListingCardImage: React.FC<IListingCardImageProps> = ({
  listing
}) => {
  const { t, i18n } = useTranslation(['explore-details']);
  const { photos: photosList = [] } = listing;

  const altTitle = Listing.getGeneratedTitle(
    t,
    'searchResult',
    listing,
    i18n.language
  );

  const photos = photosList
    .map(({ formats }) => {
      if (formats && formats.small) return formats.small.url;
      if (formats && formats.medium) return formats.medium.url;
    })
    .filter(row => row);
  const [firstPhoto = ''] = photos;

  return (
    <div className={styles.image}>
      {firstPhoto ? (
        <Image src={firstPhoto} alt={altTitle} width={365} height={220} />
      ) : (
        <div className={styles.noImage} />
      )}
      <div className={styles.price}>
        <h6>
          {getFormattedPrice(listing?.rentalPrice)}
          <span className={styles.pricePeriod}>{t('month')}</span>
        </h6>
      </div>
    </div>
  );
};
