import clsx from 'clsx';
import fetch from '../../../lib/fetch';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useState, useMemo } from 'react';
import { ListingCard } from '../../ExploreDetailsView/parts/ExploreDetailsSimilarListings/components/ListingCard/ListingCard';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { getListingUrl } from '../../../lib/listing/getListingUrl';
import { getTranslateRoutes } from '../../../lib/i18n/getTranslateRoutes';
import { useQuery } from 'react-query';

import styles from '../HomepageContent.module.css';

import type { IListing } from '../../../shared/models/listing/listing.model';

export function RecommendedListings() {
  const { t, i18n } = useTranslation(['homepage']);
  const [recentListings, setRecentListings] = useState<IListing[]>([]);
  const { district } = useSearchLocationContext();
  const cityId = useMemo(() => {
    const { id: cityId = 1 } = district || {};
    return cityId;
  }, [district]);
  const { data } = useQuery(
    ['recentListings', cityId],
    () => fetch.get(`/listings/recent?city=${cityId}`),
    {
      staleTime: 1000 * 60 * 10
    }
  );

  useEffect(() => {
    if (!data) return;
    setRecentListings(data?.results || []);
  }, [data]);

  return (
    <div className={styles.recommendedSection}>
      <h2 className={styles.smallHeading}>{t('main-heading')}</h2>
      {/* <p className={styles.smallParagraph}>{t('main-content')}</p> */}
      <div className={styles.recentListings}>
        {recentListings?.map(listing => (
          <Link href={getListingUrl(listing, i18n.language)} key={listing.id}>
            <ListingCard listing={listing} />
          </Link>
        ))}
      </div>
      <Link
        href={`${getTranslateRoutes(i18n.language, 'listing')}/${
          district?.title.toLowerCase() || 'barcelona'
        }`}
        className={styles.noDecoration}
      >
        <button className={clsx(styles.button, styles.center)}>
          {t('more')}
        </button>
      </Link>
    </div>
  );
}
