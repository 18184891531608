import React from 'react';
import { ExploreView } from '../views/ExploreView/ExploreView';
import { Providers } from '../shared/components/common/Providers/Providers';
import { SearchLocationProvider } from '../shared/components/common/SearchLocation/SearchLocation.Provider';
import Head from 'next/head';
import { SearchFormProvider } from '../shared/components/common/SearchLocation/SearchForm.Provider';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import { AlternateLinks } from '../shared/components/common/SEO/AlternateLinks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { DEFAULT_LANG } from '../shared/const/language.const';

export const Explore = () => {
  const { asPath, locale = DEFAULT_LANG } = useRouter();
  const { t } = useTranslation(['explore']);

  return (
    <>
      <Head>
        <title>{t('home-meta-title')}</title>
        <meta name="description" content={t('home-meta-desc')} />
        <meta name="theme-color" content="#FFFFFF" />
        <AlternateLinks asPath={asPath} locale={locale} withCanonical={true} />
      </Head>
      <Providers>
        <SearchLocationProvider>
          <SearchFormProvider>
            <ExploreView />
          </SearchFormProvider>
        </SearchLocationProvider>
      </Providers>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({
  locale = DEFAULT_LANG
}) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'common',
      'explore',
      'explore-details',
      'homepage'
    ]))
  }
});

export default Explore;
