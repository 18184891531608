import { FC } from 'react';

const VersionInfo: FC = () => {
  const info = `Version: ${process.env.NEXT_PUBLIC_APP_VERSION}`;
  if (process.env.NEXT_PUBLIC_APP_ENV?.toLowerCase() != 'prod') {
    return (
      <div
        className={`tw-fixed tw-bottom-0 tw-left-0 tw-py-1 tw-px-3 tw-rounded-tr-lg tw-text-small tw-bg-white tw-z-50`}
      >
        {info}
      </div>
    );
  }
  return null;
};

export default VersionInfo;
