import React from 'react';
import { ListingCardContent } from './ListingCard.Content';
import { ListingCardImage } from './ListingCard.Image';
import { ListingCardChip } from '../../../../../../shared/components/common/ListingCardChip/ListingCardChip';
import styles from './ListingCard.module.css';
import { IListing } from '../../../../../../shared/models/listing/listing.model';

interface IListingCardProps {
  listing: IListing;
  index?: number;
  onClick?: (listing: IListing) => any;
}

export const ListingCard: React.FC<IListingCardProps> = ({
  index,
  onClick,
  listing
}) => {
  return (
    <div
      tabIndex={index || 0}
      onClick={() => onClick && onClick(listing)}
      className={styles.container}
    >
      <ListingCardImage listing={listing} />
      <ListingCardContent listing={listing} />
      <ListingCardChip listing={listing} />
    </div>
  );
};
