import styles from '../HomepageContent.module.css';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

export const Agent = () => {
  const { t } = useTranslation(['homepage']);

  return (
    <section className={styles.agentSection}>
      <div className={clsx(styles.container, styles.verticalCenter)}>
        <div className={styles.agent}>
          <h3 className={clsx(styles.heading, styles.primary)}>
            {t('agent-heading')}
          </h3>
          <p className={styles.paragraph}>{t('agent-content-1')}</p>
          <p className={styles.paragraph}>{t('agent-content-2')}</p>
          <p className={styles.paragraph}>{t('agent-content-3')}</p>
          <p className={styles.paragraph}>{t('agent-content-4')}</p>
          {/* <div>
            <button className={clsx(styles.button)}>{t('saber')}</button>
          </div> */}
        </div>
      </div>
    </section>
  );
};
