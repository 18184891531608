import styles from '../HomepageContent.module.css';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export function RentingProperty() {
  const { t } = useTranslation(['homepage']);

  return (
    <div className={styles.rentingPropertyContainer}>
      <div className={clsx(styles.flexCenter, styles.rentingPropertyContent)}>
        <h2 className={clsx(styles.heading, styles.noBottomMargin)}>
          {t('rent-heading')}
        </h2>
        <p className={clsx(styles.paragraph, styles.noBottomMargin)}>
          {t('rent-content-1')}
        </p>
        <p className={clsx(styles.paragraph, styles.noBottomMargin)}>
          {t('rent-content-2')}
        </p>
        <p className={clsx(styles.paragraph, styles.noBottomMargin)}>
          {t('rent-content-3')}
        </p>
        <p className={clsx(styles.paragraph, styles.noBottomMargin)}>
          {t('rent-content-4')}
        </p>
        {/* <Link href="#">
          <a className={styles.linkWithArrow}>{t('saber')}</a>
        </Link> */}
      </div>
      <div>
        <Image
          src="/assets/images/rent-property.svg"
          alt=""
          width={493}
          height={386}
        />
      </div>
    </div>
  );
}
