import styles from '../HomepageContent.module.css';
import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

export const AppPromo = () => {
  const { t } = useTranslation(['homepage']);

  return (
    <section className={styles.appPromoSection}>
      <div className={clsx(styles.container, styles.appPromoSectionCols)}>
        <div>
          <h2 className={styles.heading}>{t('app-promo-heading')}</h2>
          <p className={styles.paragraph}>{t('app-promo-content')}</p>
          <Link href="#" className={styles.linkWithArrow}>
            {t('saber')}
          </Link>
        </div>
        <div>
          <Image
            src="/assets/images/app-promo.png"
            alt=""
            width={538}
            height={629}
          />
        </div>
      </div>
    </section>
  );
};
