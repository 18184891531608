import clsx from 'clsx';
import { RecommendedListings, RentingProperty, AboutUs, Agent } from './parts';
import { useTranslation } from 'next-i18next';

import styles from './HomepageContent.module.css';

export const HomepageContent = () => {
  const { i18n } = useTranslation();
  const showHomepageContent = i18n.language === 'es' || i18n.language === 'pt';

  return (
    <div className={styles.homepageContainer}>
      <main
        className={clsx(
          styles.main,
          showHomepageContent && styles.showHomepageContent
        )}
      >
        <div className={clsx(styles.container, styles.contentContainer)}>
          <RecommendedListings />
          {showHomepageContent && (
            <>
              <RentingProperty />
              <AboutUs />
              <Agent />
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default HomepageContent;
