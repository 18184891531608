import styles from '../HomepageContent.module.css';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

export const AboutUs = () => {
  const { t } = useTranslation(['homepage']);

  return (
    <section className={styles.aboutUsSection}>
      <div className={clsx(styles.container, styles.aboutUs)}>
        <div className={styles.aboutUsImages}>
          <img
            src="/assets/images/about-us/about-1.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="/assets/images/about-us/about-3.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="/assets/images/about-us/about-2.jpg"
            alt=""
            loading="lazy"
          />
        </div>
        <div>
          <h3 className={styles.heading}>{t('about-heading')}</h3>
          <p className={styles.paragraph}>{t('about-content-1')}</p>
          <p className={styles.paragraph}>{t('about-content-2')}</p>
          <p className={styles.paragraph}>{t('about-content-3')}</p>
          {/* <Link href="#">
            <a className={styles.linkWithArrow}>{t('saber')}</a>
          </Link> */}
        </div>
      </div>
    </section>
  );
};
