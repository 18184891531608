import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import {
  IListing,
  Listing
} from '../../../../../../shared/models/listing/listing.model';
import BedSVG from '../../../../../../public/assets/icons/bed_2.svg';
import BathubSVG from '../../../../../../public/assets/icons/bath_2.svg';
import RulerSVG from '../../../../../../public/assets/icons/ruler_2.svg';

import styles from './ListingCard.module.css';

interface IListingCardContentProps {
  listing: IListing;
}

export const ListingCardContent: React.FC<IListingCardContentProps> = ({
  listing
}) => {
  const { t, i18n } = useTranslation(['explore-details']);
  const title = Listing.getGeneratedTitle(
    t,
    'listingDetail',
    listing,
    i18n.language
  );

  if (listing?.area) {
    listing.area = Math.round(listing.area);
  }

  return (
    <div className={styles.content}>
      <div className={styles.details}>
        {listing?.bedsCount > 0 && (
          <div className={clsx(styles.detailsRow, styles.bed)}>
            <BedSVG />
            <span>
              {listing?.bedsCount} {t('beds')}
            </span>
          </div>
        )}
        <div className={clsx(styles.detailsRow, styles.bath)}>
          <BathubSVG />
          <span>
            {listing?.bathsCount} {t('baths')}
          </span>
        </div>
        <div className={clsx(styles.detailsRow, styles.square)}>
          <RulerSVG />
          <span>
            {listing?.area} {t('mt')}
            <sup>2</sup>
          </span>
        </div>
      </div>
      <h6 className={styles.contentTitle}>{title}</h6>
    </div>
  );
};
