import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import HzToolbar from '../../shared/components/layout/HzToolbar/HzToolbar';
import { HomepageContent } from '../Homepage/HomepageContent';
import VersionInfo from '../../components/ui/VersionInfo/VersionInfo';
import { useSearchLocationContext } from '../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../shared/components/common/SearchLocation/SearchForm.Provider';
import { HzFooter } from '../../shared/components/layout';
import { ExploreViewSearch } from './parts';

import styles from './ExploreView.module.css';

export const ExploreView = () => {
  const { t } = useTranslation(['explore']);
  const { district } = useSearchLocationContext();
  const { isInputFocused } = useSearchFormContext();

  useEffect(() => {
    const sort_method = { type: 'ASC', field: 'availableFrom' };
    localStorage.setItem('sort_method', JSON.stringify(sort_method));
  }, []);

  return (
    <div
      className={clsx(styles.page, isInputFocused && styles.disableScroll)}
      data-district={district?.title.toLowerCase()}
    >
      <div className={styles.contentTop}>
        <HzToolbar color="transparent" />
        <div className={styles.hero}>
          <h1 className={styles.title}>{t('title')}</h1>
          <ExploreViewSearch />
        </div>
      </div>
      <HomepageContent />
      <HzFooter className={styles.hzFooter} />
      <VersionInfo />
    </div>
  );
};
